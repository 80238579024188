import { UnauthenticatedTemplate, useAccount, useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useState } from 'react';
import { BsLock } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const { instance, accounts }: any = useMsal();
  const account = useAccount(accounts[0])
  const navigateTo = useNavigate();
  const [, setIsLoading] = useState(true);

  let roles: string[] | undefined = account?.idTokenClaims?.roles;

  const handleLoginRedirect = useCallback(async () => {
    await instance.handleRedirectPromise().then((result: { account: { idTokenClaims: { roles: string | string[]; }; }; }) => {
      if (result && result.account) {
        if (result.account.idTokenClaims.roles.includes("dpplc_user") || result.account.idTokenClaims.roles.includes("Pretty_Litter")) {
          navigateTo('/');
        }
      }
      setIsLoading(false);
    });
  }, [instance, navigateTo]);

  useEffect(() => {
    handleLoginRedirect();

    if (account) {
      if (roles?.includes("dpplc_user") || roles?.includes("Pretty_Litter")) {
        navigateTo('/');
      }
    }
  }, [account, instance, roles, handleLoginRedirect, navigateTo]);

  const handleLogin = async () => {
    try {
      await instance.loginRedirect();
      handleLoginRedirect();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  return (
    <UnauthenticatedTemplate>
      <div className="d-flex align-items-center justify-content-center vh-100" style={{ backgroundColor: "#faf3d6" }}>
        <div className="card p-5 shadow">
          <h3 className='fw-bolder'>Replenishment Plan</h3>

          <button className='btn border my-3 fw-bolder text-white py-3'
            style={{ backgroundColor: "#094780" }}
            onClick={handleLogin}>
            <span className='my-2'><BsLock /></span>  Sign In With Microsoft
          </button>
        </div>
      </div>
    </UnauthenticatedTemplate>
  )

};

export default Login;
