import { AgGridReact } from "ag-grid-react";
import { AxiosError, AxiosResponse } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useFetch from "../../../../hooks/useFetchMSAL";
import { SiteMasterFetchAPI } from "../../../../models/APIResponses.model";
import PlanningParametersCellEditTrack from "../../../../models/PlanningParameters/PlanningParametersCellEditTrack.model";
import SiteMaster from "../../../../models/MasterTables/SiteMaster.model";
import SourceMasterSlicersModel from "../../../../models/MasterTables/SourceMasterSlicers";
import {
  AG_GRID_DEFAULT_COL_DEF,
  AG_GRID_MODULES,
  AG_GRID_OPTIONS,
  SUPPLIER_MASTER_TAB_ID,
  TOAST_CONTAINER_ID,
} from "../../../../shared/constants";
import { getToastOptions, manageSourceMasterSlicersInStorage, newGridRowStyleHandler, removeInsertedRowsById } from "../../../../shared/functions";
import { RootState } from "../../../../store";
import { masterTablesTabActions } from "../../../../store/slices/master-tables-tab-slice";
import "../../../../styles.css";
import EditableGridActions from "../../../UI/EditableGridActions/EditableGridActions";

const SupplierMasterGrid = (props: {
  gridHeight: string;
}) => {
  const [{ data, loading, error }, fetchData] = useFetch([]);

  // States
  const [agRowData, setAgRowData] = useState<SiteMaster[]>([]);
  const [agRowDataCopy, setAgRowDataCopy] = useState<SiteMaster[]>([]);
  const [cellEditTracks, setCellEditTracks] =
    useState<PlanningParametersCellEditTrack>({});
  const [selectedGridSlicers,] =
    useState<SourceMasterSlicersModel>(manageSourceMasterSlicersInStorage);
  const [changeTrackState, setChangeTrackState] = useState(false);
  const [insertedRows, setInsertedRows] = useState<any>({});
  const [selectedRowIds, setSelectedRowIds] = useState<any[]>([]);
  const [newRowCount, setNewRowCount] = useState<number>(1);
  const [enableDelButton, setEnableDelButton] = useState(false);

  // Variables
  const agGridRef = useRef<AgGridReact>(null);

  // Redux variables
  const dispatch = useDispatch();
  const isSupplierMasterUpdated = useSelector(
    (state: RootState) =>
      state.masterTables.isSupplierMasterUpdated
  );
  const tabApiDataFetched: boolean = useSelector((state: RootState) => state.masterTables.tabApiDataFetched)[SUPPLIER_MASTER_TAB_ID];
  const activeSubTabId: string = useSelector((state: RootState) => state.masterTables.activeSubTabId);

  const ColDef: any[] = [
    {
      headerName: "Supplier Display Name",
      field: "supplier_display_name",
      cellDataType: "text",
      cellEditor: "agTextCellEditor",
      width: 350,
      editable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      suppressHeaderMenuButton: true,
      sortable: false,
    },
  ];

  /**
   * Fetches Part Master data from API
   * @param {any} body Request body
   */
  const fetchSupplyMaster = useCallback(
    async (body: any): Promise<void> => {
      setChangeTrackState(false);
      const fetchingDataToastId = toast.loading("Fetching Supplier Master data...", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      });
      try {
        const supplierMasterFetchAPIResponse: AxiosResponse<SiteMasterFetchAPI> =
          await fetchData(
            `/get-supplier-master`,
            {
              method: 'POST',
              data: body
            }
          );

        toast.dismiss({
          id: fetchingDataToastId,
          containerId: TOAST_CONTAINER_ID,
        });

        if (supplierMasterFetchAPIResponse.data.data) {
          setAgRowData(supplierMasterFetchAPIResponse.data.data);
          setAgRowDataCopy(
            JSON.parse(JSON.stringify(supplierMasterFetchAPIResponse.data.data))
          );
          dispatch(masterTablesTabActions.setTabApiDataFetched({
            [SUPPLIER_MASTER_TAB_ID]: true
          }));

        } else {
          toast.error("Error in fetching Supplier Master data", {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("error"),
          });
        }
      } catch (error: Error | any) {
        console.error(`Request Error: ${error.name}`);
        toast.dismiss({
          id: fetchingDataToastId,
          containerId: TOAST_CONTAINER_ID,
        });
        toast.error("Error in fetching Supplier Master data", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    },
    [dispatch, fetchData, TOAST_CONTAINER_ID]
  );

  /**
   * Event handler for when cell editing is stopped
   * @param {any} params Cell editing stop event parameters
   */
  const onCellEditingStoppedHandler = (params: any) => {
    try {
      var id: string = params.node.data.supplier_code;
      const colName = params.column.colId;

      if (id.toString().startsWith("new_")) {
        setInsertedRows((prev: any) => {
          let newData = prev;
          let newRow = newData[id] ? newData[id] : {};
          newRow[colName] = params.newValue;
          newData[id] = newRow;
          return newData;
        });
      } else {
        if (params.oldValue !== params.newValue && params.newValue !== undefined) {
          dispatch(masterTablesTabActions.setIsMasterTablesEdited({ key: 'supplierMaster', value: true }));
          let currentRowNode = agGridRef.current!.api!.getRowNode(
            params.node.id
          );

          setCellEditTracks((prev) => {
            let newTrackEdits = prev;
            let cellEditTrack = newTrackEdits[id] ? newTrackEdits[id] : {};
            cellEditTrack[colName] = params.newValue;
            newTrackEdits[id] = cellEditTrack;
            return newTrackEdits;
          });

          setChangeTrackState(true);
          agGridRef.current!.api!.applyTransaction({
            update: [currentRowNode?.data],
          });
        }
      }
    } catch (error: any) {
      console.error(`Error: ${error.name}`);
    }
  };

  /**
   * Sets selected rows on selection checkbox change
   */
  const onSelectionChangedHandler = () => {
    const selectedRows = agGridRef.current!.api!.getSelectedRows();
    const selectedRowIds: any[] = selectedRows.map((el) => el.id);
    setSelectedRowIds(selectedRowIds);
  };

  /**
   * Deletes selected rows
   * @param {any[]} selectedRowIds Selected Row IDs
   */
  const deleteSelectedRowData = async (selectedRowId: any[]) => {
    let deleteToastId;

    try {
      // Delete selected newly inserted rows
      const oldDataRowIds: any[] = selectedRowIds.filter(rowId => !rowId.toString().startsWith("new_"));
      setInsertedRows((insertedRows: any) => removeInsertedRowsById(insertedRows, selectedRowIds));

      if (oldDataRowIds.length) {
        deleteToastId = toast.loading("Deleting Row...", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("loading"),
        });

        const deleteReqResponse: AxiosResponse =
          await fetchData(
            `/delete-supplier-master`,
            {
              method: 'DELETE',
              data: { row_ids_to_delete: selectedRowId },
            }
          );

        toast.dismiss({
          id: deleteToastId,
          containerId: TOAST_CONTAINER_ID,
        });

        if (deleteReqResponse.data.success) {
          setCellEditTracks({});
          setInsertedRows({});

          toast.success("Data updated successfully", {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("success"),
          });

          dispatch(masterTablesTabActions.setTabApiDataFetched({
            [SUPPLIER_MASTER_TAB_ID]: false
          }));

        } else if (deleteReqResponse.data.error) {
          toast.error(deleteReqResponse.data.error, {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions("error"),
          });
        }
      } else {
        const selectedRows: any[] | undefined = agGridRef.current?.api.getSelectedRows();
        agGridRef.current?.api.applyTransaction({
          remove: selectedRows
        });
      }
    } catch (error: AxiosError | any) {
      console.error(error);
      toast.error("Something went wrong.", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  };

  /**
   * Updates cell values by using part site source update API
   * @param {any} cellEditTracks Cell edits in the grid
   * @param {any} insertedRows Newly inserted rows
   */
  const updateCellValues = async (
    cellEditTracks: any,
    insertedRow: any
  ): Promise<void> => {
    try {
      const updateToastId = toast.loading("Updating Supplier Master data...", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("loading"),
      });

      const updateReqResponse: AxiosResponse =
        await fetchData(
          `/update-supplier-master`,
          {
            method: 'PUT',
            data: {
              updated_data: cellEditTracks,
              inserted_data: insertedRow
            }
          }
        );
      toast.dismiss({
        id: updateToastId,
        containerId: TOAST_CONTAINER_ID,
      });

      if (updateReqResponse.data.success) {
        setCellEditTracks({});
        setInsertedRows({});

        dispatch(masterTablesTabActions.setIsMasterTablesEdited({ key: 'supplierMaster', value: false }));
        dispatch(masterTablesTabActions.setIsSupplierMasterUpdated(!isSupplierMasterUpdated));

        toast.success("Supplier Master data updated successfully", {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("success"),
        });

        dispatch(masterTablesTabActions.setTabApiDataFetched({
          [SUPPLIER_MASTER_TAB_ID]: false
        }));

      } else if (updateReqResponse.data.error) {
        toast.error(updateReqResponse.data.error, {
          containerId: TOAST_CONTAINER_ID,
          ...getToastOptions("error"),
        });
      }
    } catch (error: AxiosError | any) {
      console.error(error);
      toast.error("Error in updating the Supplier Master data", {
        containerId: TOAST_CONTAINER_ID,
        ...getToastOptions("error"),
      });
    }
  };

  /**
   * Event handler for save button click
   * @param {any} event Save button click event data
   */
  const onUpdateCellValuesHandler = async (event: any): Promise<void> => {
    try {
      const updatedNewRowData: any = {};
      for (let key in insertedRows) {
        if (Object.keys(insertedRows[key]).length) {
          updatedNewRowData[key] = insertedRows[key];
        }
      }

      if (
        Object.keys(cellEditTracks).length ||
        Object.keys(updatedNewRowData).length
      ) {
        updateCellValues(cellEditTracks, updatedNewRowData);
      }
    } catch (error: Error | any) {
      console.error(`Error: ${error.name}`);
    }
  };

  /**
   * Event handler for reset button click
   * @param {any} params Reset button click event data
   */
  const resetCellEdits = async (params: any): Promise<void> => {
    try {
      const resetConsent: boolean = window.confirm(
        "Do you want to reset these changes?"
      );

      if (resetConsent) {
        setCellEditTracks({});
        setChangeTrackState(false);
        setAgRowDataCopy(JSON.parse(JSON.stringify([...agRowData])));
        dispatch(masterTablesTabActions.setIsMasterTablesEdited({ key: 'supplierMaster', value: false }));

        // removeEditedCellHighlights();
      }
    } catch (error: Error | any) {
      console.error(`Error: ${error.name}`);
    }
  };

  const gridOptions = {
    ...(AG_GRID_OPTIONS as any),
    onSelectionChanged: onSelectionChangedHandler,
    getRowStyle: (params: any) => newGridRowStyleHandler(params, "id")
  };

  /**
   * Inserts a new row into the grid
   */
  const addRowToGrid = (): void => {
    const newRowId: string = `new_${newRowCount.toString()}`;
    agGridRef.current!.api!.applyTransaction({
      add: [{ id: newRowId }],
      addIndex: 0,
    });
    setInsertedRows((insertedRows: any) => {
      return {
        ...insertedRows,
        [newRowId]: {}
      };
    });
    dispatch(masterTablesTabActions.setIsMasterTablesEdited({ key: 'supplierMaster', value: true }));
    setChangeTrackState(true);
    setNewRowCount(newRowCount + 1);
  };

  /**
   * Deletes selected rows from the grid
   */
  const deleteSelectedRowsFromGrid = (): void => {
    deleteSelectedRowData(selectedRowIds);
    setSelectedRowIds([]);
    setEnableDelButton(false);
  };

  const defaultColDef = {
    autoHeight: true,
    ...(AG_GRID_DEFAULT_COL_DEF as any),
  };

  useEffect(() => {
    if (
      !tabApiDataFetched &&
      activeSubTabId === SUPPLIER_MASTER_TAB_ID
    ) {
      fetchSupplyMaster(selectedGridSlicers);
    }
  }, [fetchSupplyMaster, selectedGridSlicers, tabApiDataFetched, activeSubTabId]);

  useEffect(() => {
    if (selectedRowIds.length > 0) {
      setEnableDelButton(true);
    } else {
      setEnableDelButton(false);
    }
  }, [selectedRowIds]);

  useEffect(() => {
    if (
      Object.keys(insertedRows).length === 0 &&
      Object.keys(cellEditTracks).length === 0
    ) {
      setChangeTrackState(false);
    }
  }, [insertedRows, cellEditTracks]);

  return (
    <>
      <div className="row mb-2">
        <div className="col offset-8 col-4 text-end">
          <EditableGridActions
            disableIf={{
              resetBtn: !changeTrackState,
              saveBtn: !changeTrackState,
              deleteRowBtn: !enableDelButton
            }}
            onClick={{
              resetBtn: resetCellEdits,
              saveBtn: onUpdateCellValuesHandler,
              addRowBtn: addRowToGrid,
              deleteRowBtn: deleteSelectedRowsFromGrid
            }}
          />
        </div>
      </div>

      <div
        className="ag-theme-balham mt-2"
        style={{
          height: props.gridHeight,
          maxHeight: props.gridHeight,
          maxWidth: "25vw",
          width: "100%",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        <AgGridReact
          ref={agGridRef}
          rowData={agRowDataCopy}
          columnDefs={ColDef}
          defaultColDef={defaultColDef}
          rowSelection={"multiple"}
          onCellEditingStopped={onCellEditingStoppedHandler}
          gridOptions={gridOptions}
          modules={AG_GRID_MODULES}
        />
      </div>
    </>
  );
};

export default SupplierMasterGrid;

