import { useEffect, useState } from "react";
import { LuWarehouse } from "react-icons/lu";
import { BiReset } from "react-icons/bi";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";

import SourceMasterSlicersModel from "../../../../models/MasterTables/SourceMasterSlicers";
import { masterTablesTabActions } from "../../../../store/slices/master-tables-tab-slice";
import { SOURCE_MASTER_TAB_ID } from "../../../../shared/constants";

const SourceMasterSlicers = (props: {
  slicers: SourceMasterSlicersModel;
  selectedSlicers: SourceMasterSlicersModel;
  setSelectedSlicers: React.Dispatch<
    React.SetStateAction<SourceMasterSlicersModel>
  >;
}) => {
  const [filteredPropsSlicers, setFilteredPropsSlicers] =
    useState<SourceMasterSlicersModel>(props.slicers);
  const [tempSelectedSlicers, setTempSelectedSlicers] =
    useState<SourceMasterSlicersModel>({
      site_code: ["All"],
      supplier_code: ["All"],
    });

  const dispatchFn = useDispatch();

  // Set slicers from props to filtered slicers on change
  useEffect(() => {
    // Add "All" slicer item at index 0 for each slicer key.
    let updatedSlicers: any = { ...props.slicers };

    Object.keys(updatedSlicers).forEach((key) => {
      // if (key !== "Planning_Bucket") {
      if (updatedSlicers[key].indexOf("All") > -1) {
        updatedSlicers[key].splice(updatedSlicers[key].indexOf("All"), 1);
        updatedSlicers[key].unshift("All");
      }
      // }
    });

    setFilteredPropsSlicers({ ...updatedSlicers });
  }, [props.slicers]);

  // Event handler for checkbox changes
  const slicerCheckboxChangeHandler = (event: any): void => {
    try {
      const { value, checked } = event.target;
      let newSelectedSlicers: any = { ...tempSelectedSlicers };
      let slicerKey: "site_code" | "supplier_code" = event.target.id.includes(
        "site_code"
      )
        ? "site_code"
        : "supplier_code";

      if (checked) {
        if (value === "All") {
          newSelectedSlicers[slicerKey] = ["All"];
        } else {
          newSelectedSlicers[slicerKey].push(value);
          newSelectedSlicers[slicerKey] = [
            ...newSelectedSlicers[slicerKey].filter(
              (value: string) => value !== "All"
            ),
          ];
        }
      } else {
        newSelectedSlicers[slicerKey].splice(
          newSelectedSlicers[slicerKey].indexOf(value),
          1
        );
      }

      Object.keys(newSelectedSlicers).forEach((key) => {
        if (newSelectedSlicers[key].length === 0) {
          newSelectedSlicers[key] = ["All"];
        }
      });

      sessionStorage.setItem(
        "selectedSourceMasterSlicers",
        JSON.stringify(newSelectedSlicers)
      );
      setTempSelectedSlicers({ ...newSelectedSlicers });
    } catch (error: any) {
      console.error(error);
    }
  };

  // Event handler for dropdown filter text change
  const dropdownFilterChangeHandler = (
    event: any,
    key: "site_code" | "supplier_code"
  ) => {
    setTimeout(() => {
      setFilteredPropsSlicers((prev: any) => {
        const filterText: string = event.target.value;
        const filteredKeySlicers: string[] =
          filterText.length > 0
            ? [
              ...props.slicers[key].filter((slicer: any) =>
                slicer
                  .toLowerCase()
                  .includes(event.target.value.toLowerCase())
              ),
            ]
            : props.slicers[key];

        return {
          ...prev,
          [key]: filteredKeySlicers,
        };
      });
    }, 500);
  };

  // Function to set selected filters
  const setSelectedFilters = () => {
    props.setSelectedSlicers(
      JSON.parse(
        sessionStorage.getItem("selectedSourceMasterSlicers") as string
      )
    );
    dispatchFn(
      masterTablesTabActions.setTabApiDataFetched({
        [SOURCE_MASTER_TAB_ID]: false,
      })
    );
  };

  // Event handler to reset selected filters
  const resetSelectedFilters = (event: any) => {
    if (
      tempSelectedSlicers.site_code.toString() !== ["All"].toString() ||
      tempSelectedSlicers.supplier_code.toString() !== ["All"].toString()
    ) {
      const newSelectedSlicers: SourceMasterSlicersModel = {
        site_code: ["All"],
        supplier_code: ["All"],
      };

      sessionStorage.setItem(
        "selectedSourceMasterSlicers",
        JSON.stringify(newSelectedSlicers)
      );
      setTempSelectedSlicers({ ...newSelectedSlicers });
      props.setSelectedSlicers({ ...newSelectedSlicers });
      dispatchFn(
        masterTablesTabActions.setTabApiDataFetched({
          [SOURCE_MASTER_TAB_ID]: false,
        })
      );
    }
  };

  return (
    <>
      <div className="col">
        <div className="dropdown d-inline me-1">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <LuWarehouse /> &nbsp;Site Name: {(
                (tempSelectedSlicers.site_code.length > 1) ?
                  "Multiple" : tempSelectedSlicers.site_code[0]
              )}
            </span>
          </button>

          <ul className="dropdown-menu scrollable-dropdown">
            <input
              type="text"
              id="site-master-filter-input"
              className="form-control form-control-sm ms-2 mb-2"
              style={{ width: "90%" }}
              placeholder={"\u{1F50E} Search..."}
              onChange={(event) =>
                dropdownFilterChangeHandler(event, "site_code")
              }
            />
            {Object.keys(filteredPropsSlicers).length > 0 &&
              filteredPropsSlicers.site_code.map(
                (siteMaster: any, siteMasterIdx: any) =>
                  siteMaster === "All" ? (
                    <li key={`site_code-${siteMasterIdx}`}>
                      <a className="dropdown-item">
                        <div className="form-check text-dark">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`site_code-${siteMasterIdx}`}
                            onChange={slicerCheckboxChangeHandler}
                            value={siteMaster}
                            checked={
                              tempSelectedSlicers.site_code.findIndex(
                                (value: string) => value === "All"
                              ) !== -1
                            }
                          />
                          <label
                            htmlFor={`site_code-${siteMasterIdx}`}
                            className="form-check-label"
                          >
                            {siteMaster}
                          </label>
                        </div>
                      </a>
                    </li>
                  ) : (
                    <li key={`site_code-${siteMasterIdx}`}>
                      <a className="dropdown-item">
                        <div className="form-check text-dark">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`site_code-${siteMasterIdx}`}
                            onChange={slicerCheckboxChangeHandler}
                            value={siteMaster}
                            checked={
                              tempSelectedSlicers.site_code.findIndex(
                                (value: string) => value === siteMaster
                              ) !== -1
                            }
                          />
                          <label
                            htmlFor={`site_code-${siteMasterIdx}`}
                            className="form-check-label"
                          >
                            {siteMaster}
                          </label>
                        </div>
                      </a>
                    </li>
                  )
              )}
          </ul>
        </div>

        <div className="dropdown d-inline me-1">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <LuWarehouse /> &nbsp;Supplier Name: {(
                (tempSelectedSlicers.supplier_code.length > 1) ?
                  "Multiple" : tempSelectedSlicers.supplier_code[0]
              )}
            </span>
          </button>

          <ul className="dropdown-menu scrollable-dropdown">
            <input
              type="text"
              id="supplier-master-filter-input"
              className="form-control form-control-sm ms-2 mb-2"
              style={{ width: "90%" }}
              placeholder={"\u{1F50E} Search..."}
              onChange={(event) =>
                dropdownFilterChangeHandler(event, "supplier_code")
              }
            />
            {Object.keys(filteredPropsSlicers).length > 0 &&
              filteredPropsSlicers.supplier_code.map(
                (supplierMaster: any, supplierMasterIdx: any) =>
                  supplierMaster === "All" ? (
                    <li key={`supplier_code-${supplierMasterIdx}`}>
                      <a className="dropdown-item">
                        <div className="form-check text-dark">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`supplier_code-${supplierMasterIdx}`}
                            onChange={slicerCheckboxChangeHandler}
                            value={supplierMaster}
                            checked={
                              tempSelectedSlicers.supplier_code.findIndex(
                                (value: string) => value === "All"
                              ) !== -1
                            }
                          />
                          <label
                            htmlFor={`supplier_code-${supplierMasterIdx}`}
                            className="form-check-label"
                          >
                            {supplierMaster}
                          </label>
                        </div>
                      </a>
                    </li>
                  ) : (
                    <li key={`supplier_code-${supplierMasterIdx}`}>
                      <a className="dropdown-item">
                        <div className="form-check text-dark">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`supplier_code-${supplierMasterIdx}`}
                            onChange={slicerCheckboxChangeHandler}
                            value={supplierMaster}
                            checked={
                              tempSelectedSlicers.supplier_code.findIndex(
                                (value: string) => value === supplierMaster
                              ) !== -1
                            }
                          />
                          <label
                            htmlFor={`supplier_code-${supplierMasterIdx}`}
                            className="form-check-label"
                          >
                            {supplierMaster}
                          </label>
                        </div>
                      </a>
                    </li>
                  )
              )}
          </ul>
        </div>

        <div className="d-inline me-1">
          <button type="button" className="btn btn-sm text-white" style={{ backgroundColor: "#094780" }} onClick={setSelectedFilters}>
            <div className="d-flex justify-content-center align-items-center">
              <IoChevronForwardCircleOutline size={20} /> &nbsp;Apply filters
            </div>
          </button>
        </div>

        <div className="d-inline">
          <button type="button" className="btn btn-sm btn-warning" onClick={resetSelectedFilters}>
            <div className="d-flex justify-content-center align-items-center">
              <BiReset size={20} /> &nbsp;Reset filters
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default SourceMasterSlicers;
