import { useEffect, useState } from "react";

import "../../../styles.css";
import TabDetail from "../../../models/TabDetail.model";
import Tab from "./Tab";

const Tabs = (props: {
    tabs: TabDetail[];
    activeTabIndex: number;
    getActiveTabId: (tabId: string) => void;
}) => {
    // Variables
    const { tabs, activeTabIndex, getActiveTabId } = { ...props };

    // States
    const [activeTabId, setActiveTabId] = useState<string>(tabs[(
        activeTabIndex > -1 ? activeTabIndex : 0
    )].id);

    // Function to handle tab click
    const onTabClick = (tabId: string) => {
        setActiveTabId(tabId);

        if (getActiveTabId) {
            getActiveTabId(tabId);
        }
    }

    // Side-effect to set active tab if only 1 tab is open
    useEffect(() => {
        if (tabs.length === 1) {
            setActiveTabId(tabs[0].id);
        }
    }, [tabs]);

    return (
        <div>
            <ul className="nav nav-tabs">
                {
                    tabs.map((tab, idx) => (
                        <Tab
                            key={`nav-tab-${idx}`}
                            id={tab.id}
                            name={tab.name}
                            icon={tab.icon}
                            activeTabId={activeTabId}
                            getTabId={onTabClick}
                            className={`d-flex align-items-center justify-content-center rounded-top custom-border-left custom-border-top custom-border-right ${
                                (activeTabId === tab.id) ? '' : 'custom-border-bottom opacity-50 bg-secondary-subtle'
                            }`}
                        />
                    ))
                }
            </ul>

            <div className="tab-content mt-2">
                {
                    tabs.map((tab, idx) => (
                        <div className={`tab-pane ${
                            (activeTabId === tab.id) ? 'active' : ''
                        }`} id={tab.id} key={`tab-content-${idx}`}>
                            {tab.content}
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Tabs;
