import { useEffect, useState } from "react";
import { LuPackageSearch, LuWarehouse } from "react-icons/lu";
import { BiReset } from "react-icons/bi";
import { IoChevronForwardCircleOutline } from "react-icons/io5";

import PartSiteSourceSlicersModel from "../../../models/PlanningParameters/PartSiteSourceSlicers.model";
import { useDispatch } from "react-redux";
import PartSiteSourceMapping from "../../../models/PlanningParameters/PartSiteSourceMapping.model";
import { planningParametersTabActions } from "../../../store/slices/planning-parameters-tab-slice";

const PlanningParameterSlicers = (props: {
  slicers: PartSiteSourceSlicersModel;
  selectedSlicers: PartSiteSourceSlicersModel;
  partSiteSourceMapping: PartSiteSourceMapping[];
}) => {
  const [filteredPropsSlicers, setFilteredPropsSlicers] =
    useState<PartSiteSourceSlicersModel>(props.slicers);
  const [tempSelectedSlicers, setTempSelectedSlicers] =
    useState<PartSiteSourceSlicersModel>({
      part_name: ["All"],
      source_value: ["All"],
      location_name: ["All"],
    });

  // Variables
  const dispatch = useDispatch();

  // Set slicers from props to filtered slicers on change
  useEffect(() => {
    // Add "All" slicer item at index 0 for each slicer key.
    let updatedSlicers: any = { ...props.slicers };

    Object.keys(updatedSlicers).forEach((key) => {
      if (Array.isArray(updatedSlicers[key])) {
        const index = updatedSlicers[key].indexOf("All");
        if (index > -1) {
          const updatedArray = [
            "All",
            ...updatedSlicers[key].slice(0, index),
            ...updatedSlicers[key].slice(index + 1),
          ];
          updatedSlicers[key] = updatedArray;
        }
      }
    });

    setFilteredPropsSlicers({ ...updatedSlicers });
  }, [props.slicers]);

  function addIfNotPresent(arr: string[], element: string) {
    if (!arr.includes(element)) {
      arr.push(element);
    }
  }


  // Event handler for checkbox changes
  const slicerCheckboxChangeHandler = (event: any): void => {
    try {
      const { value, checked } = event.target;
      var newSelectedSlicers: any = { ...tempSelectedSlicers };
      let slicerKey: "part_name" | "source_value" | "location_name" =
        event.target.id.includes("part_name")
          ? "part_name"
          : event.target.id.includes("source_value")
            ? "source_value"
            : "location_name";
      let allSlicers = ["part_name", "source_value", "location_name"];

      if (checked) {
        if (value === "All") {
          newSelectedSlicers[slicerKey] = ["All"];

        } else {
          newSelectedSlicers[slicerKey] = [...newSelectedSlicers[slicerKey].filter((value: string) => value !== "All"), value];
        }

      } else {
        let vb = { ...newSelectedSlicers };
        vb[slicerKey] = vb[slicerKey].filter((v1: any) => v1 !== value);
        newSelectedSlicers = { ...vb };
        setTempSelectedSlicers(newSelectedSlicers);
      }

      Object.keys(newSelectedSlicers).forEach((key) => {
        if (newSelectedSlicers[key].length === 0) {
          newSelectedSlicers[key] = ["All"];
        }
      });

      //Set the other slicers as per mapping when part_code slicer is selected

      if (!newSelectedSlicers[slicerKey].includes("All")) {
        var slicerKeyWithAll: string[] = [];
        var slicerKeyWithoutAll: string[] = [];
        if (checked) {
          setTempSelectedSlicers(newSelectedSlicers);
        }

        if (
          newSelectedSlicers[slicerKey].includes("All") &&
          newSelectedSlicers[slicerKey].length > 0
        ) {
          newSelectedSlicers[slicerKey] = newSelectedSlicers[slicerKey].filter(
            (v1: any) => v1 !== "All"
          );
        }
        Object.keys(newSelectedSlicers).forEach((el: string) => {
          const typedKEy = el as keyof PartSiteSourceSlicersModel;
          if (
            newSelectedSlicers[typedKEy].findIndex(
              (v: string) => v === "All"
            ) !== -1
          ) {
            slicerKeyWithAll.push(el);
          } else {
            slicerKeyWithoutAll.push(el);
          }
        });

        let filtered = props.partSiteSourceMapping.filter((value: any) => {
          var returnable_boolean: boolean[] = [];
          slicerKeyWithoutAll.forEach((vl: any) => {
            const returnable_boolean1 =
              newSelectedSlicers[
                vl as keyof PartSiteSourceSlicersModel
              ].findIndex((secSli: any) => secSli === value[vl]) !== -1;
            returnable_boolean.push(returnable_boolean1);
          });
          const anyTrue = returnable_boolean.reduce(
            (accumulator: boolean, currentValue: boolean) => {
              // If the accumulator is already true, it will remain true
              return accumulator && currentValue;
            },
            true
          );
          return anyTrue;
        });

        sessionStorage.setItem(
          "selectedSlicers",
          JSON.stringify(newSelectedSlicers)
        );

        setFilteredPropsSlicers((prev: any) => {
          let changes = { ...prev };
          slicerKeyWithAll.forEach((el1) => {
            changes[el1] = ["All"];
            filtered.forEach((el: any) => {
              addIfNotPresent(changes[el1], el[el1]);
            });
          });
          return changes;
        });
      } else {
        setTempSelectedSlicers(newSelectedSlicers);
        let filtered = props.partSiteSourceMapping.filter((value: any) => {
          var returnable_boolean: boolean[] = [];
          allSlicers.forEach((vl: any) => {
            const returnable_boolean1 =
              newSelectedSlicers[
                vl as keyof PartSiteSourceSlicersModel
              ].findIndex((secSli: any) => secSli === value[vl]) !== -1 ||
              newSelectedSlicers[vl].includes("All");
            returnable_boolean.push(returnable_boolean1);
          });
          const anyTrue = returnable_boolean.reduce(
            (accumulator: boolean, currentValue: boolean) => {
              // If the accumulator is already true, it will remain true
              return accumulator && currentValue;
            },
            true
          );
          return anyTrue;
        });

        let filtered1 = props.partSiteSourceMapping.filter((value: any) => {
          var returnable_boolean: boolean[] = [];
          allSlicers
            .filter((value) => newSelectedSlicers[value].includes("All"))
            .forEach((vl: any) => {
              const returnable_boolean1 =
                newSelectedSlicers[
                  vl as keyof PartSiteSourceSlicersModel
                ].findIndex((secSli: any) => secSli === value[vl]) !== -1 ||
                newSelectedSlicers[vl].includes("All");
              returnable_boolean.push(returnable_boolean1);
            });
          const anyTrue = returnable_boolean.reduce(
            (accumulator: boolean, currentValue: boolean) => {
              // If the accumulator is already true, it will remain true
              return accumulator && currentValue;
            },
            true
          );
          return anyTrue;
        });

        setFilteredPropsSlicers((prev: any) => {
          let changes = { ...prev };
          allSlicers
            .filter((value) => newSelectedSlicers[value].includes("All"))
            .forEach((el1) => {
              changes[el1] = ["All"];
              filtered.forEach((el: any) => {
                addIfNotPresent(changes[el1], el[el1]);
              });
            });
          allSlicers
            .filter((value) => !newSelectedSlicers[value].includes("All"))
            .forEach((el1) => {
              changes[el1] = ["All"];
              filtered1.forEach((el: any) => {
                addIfNotPresent(changes[el1], el[el1]);
              });
            });
          return changes;
        });
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  // Event handler for dropdown filter text change
  const dropdownFilterChangeHandler = (
    event: any,
    key: "part_name" | "source_value" | "location_name"
  ) => {
    setTimeout(() => {
      setFilteredPropsSlicers((prev: any) => {
        const filterText: string = event.target.value;
        const filteredKeySlicers: string[] =
          filterText.length > 0
            ? [
              ...props.slicers[key].filter((slicer: any) =>
                slicer
                  .toLowerCase()
                  .includes(event.target.value.toLowerCase())
              ),
            ]
            : props.slicers[key];

        return {
          ...prev,
          [key]: filteredKeySlicers,
        };
      });
    }, 500);
  };

  // Function to set selected filters
  const setSelectedFilters = () => {
    dispatch(
      planningParametersTabActions.setSelectedGridSlicers(
        JSON.parse(sessionStorage.getItem("selectedSlicers") as string)
      )
    );
    dispatch(planningParametersTabActions.resetTabApiDataFetched(null));
  };

  // Event handler to reset selected filters
  const resetSelectedFilters = (event: any) => {
    if (
      tempSelectedSlicers.part_name.toString() !== ["All"].toString() ||
      tempSelectedSlicers.source_value.toString() !== ["All"].toString() ||
      tempSelectedSlicers.location_name.toString() !== ["All"].toString()
      // props.selectedSlicers.Planning_Bucket !== 12
    ) {
      const newSelectedSlicers: PartSiteSourceSlicersModel = {
        part_name: ["All"],
        source_value: ["All"],
        location_name: ["All"],
      };

      sessionStorage.setItem(
        "selectedSlicers",
        JSON.stringify(newSelectedSlicers)
      );
      setTempSelectedSlicers({ ...newSelectedSlicers });
      dispatch(
        planningParametersTabActions.setSelectedGridSlicers({
          ...newSelectedSlicers,
        })
      );
      dispatch(planningParametersTabActions.resetTabApiDataFetched(null));
    }
  };

  return (
    <>
      <div className="col">
        <div className="dropdown d-inline me-1">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <LuPackageSearch /> &nbsp;Part Code: {(
                (tempSelectedSlicers.part_name.length > 1) ?
                  "Multiple" : tempSelectedSlicers.part_name[0]
              )}
            </span>
          </button>

          <ul className="dropdown-menu scrollable-dropdown">
            <input
              type="text"
              id="warehouse-filter-input"
              className="form-control form-control-sm ms-2 mb-2"
              style={{ width: "90%" }}
              placeholder={"\u{1F50E} Search..."}
              onChange={(event) =>
                dropdownFilterChangeHandler(event, "part_name")
              }
            />
            {Object.keys(filteredPropsSlicers).length > 0 &&
              filteredPropsSlicers.part_name.map(
                (partCode: any, partCodeIdx: any) =>
                  partCode === "All" ? (
                    <li key={`part_name-${partCodeIdx}`}>
                      <a className="dropdown-item">
                        <div className="form-check text-dark">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`part_name-${partCodeIdx}`}
                            onChange={slicerCheckboxChangeHandler}
                            value={partCode}
                            checked={
                              tempSelectedSlicers.part_name.findIndex(
                                (value: string) => value === "All"
                              ) !== -1
                            }
                          />
                          <label
                            htmlFor={`part_name-${partCodeIdx}`}
                            className="form-check-label"
                          >
                            {partCode}
                          </label>
                        </div>
                      </a>
                    </li>
                  ) : (
                    <li key={`part_name-${partCodeIdx}`}>
                      <a className="dropdown-item">
                        <div className="form-check text-dark">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`part_name-${partCodeIdx}`}
                            onChange={slicerCheckboxChangeHandler}
                            value={partCode}
                            checked={
                              tempSelectedSlicers.part_name.findIndex(
                                (value: string) => value === partCode
                              ) !== -1
                            }
                          />
                          <label
                            htmlFor={`part_name-${partCodeIdx}`}
                            className="form-check-label"
                          >
                            {partCode}
                          </label>
                        </div>
                      </a>
                    </li>
                  )
              )}
          </ul>
        </div>

        <div className="dropdown d-inline me-1">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <LuWarehouse /> &nbsp;Source Value: {(
                (tempSelectedSlicers.source_value.length > 1) ?
                  "Multiple" : tempSelectedSlicers.source_value[0]
              )}
            </span>
          </button>

          <ul className="dropdown-menu scrollable-dropdown">
            <input
              type="text"
              id="warehouse-filter-input"
              className="form-control form-control-sm ms-2 mb-2"
              style={{ width: "90%" }}
              placeholder={"\u{1F50E} Search..."}
              onChange={(event) =>
                dropdownFilterChangeHandler(event, "source_value")
              }
            />
            {Object.keys(filteredPropsSlicers).length > 0 &&
              filteredPropsSlicers.source_value.map(
                (sourceValue: any, sourceValueIdx: any) =>
                  sourceValue === "All" ? (
                    <li key={`source_value-${sourceValueIdx}`}>
                      <a className="dropdown-item">
                        <div className="form-check text-dark">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`source_value-${sourceValueIdx}`}
                            onChange={slicerCheckboxChangeHandler}
                            value={sourceValue}
                            checked={
                              tempSelectedSlicers.source_value.findIndex(
                                (value: string) => value === "All"
                              ) !== -1
                            }
                          />
                          <label
                            htmlFor={`source_value-${sourceValueIdx}`}
                            className="form-check-label"
                          >
                            {sourceValue}
                          </label>
                        </div>
                      </a>
                    </li>
                  ) : (
                    <li key={`source_value-${sourceValueIdx}`}>
                      <a className="dropdown-item">
                        <div className="form-check text-dark">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`source_value-${sourceValueIdx}`}
                            onChange={slicerCheckboxChangeHandler}
                            value={sourceValue}
                            checked={
                              tempSelectedSlicers.source_value.findIndex(
                                (value: string) => value === sourceValue
                              ) !== -1
                            }
                          />
                          <label
                            htmlFor={`source_value-${sourceValueIdx}`}
                            className="form-check-label"
                          >
                            {sourceValue}
                          </label>
                        </div>
                      </a>
                    </li>
                  )
              )}
          </ul>
        </div>

        <div className="dropdown d-inline me-1">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <LuWarehouse /> &nbsp;Site Name: {(
                (tempSelectedSlicers.location_name.length > 1) ?
                  "Multiple" : tempSelectedSlicers.location_name[0]
              )}
            </span>
          </button>

          <ul className="dropdown-menu scrollable-dropdown">
            <input
              type="text"
              id="warehouse-filter-input"
              className="form-control form-control-sm ms-2 mb-2"
              style={{ width: "90%" }}
              placeholder={"\u{1F50E} Search..."}
              onChange={(event) =>
                dropdownFilterChangeHandler(event, "location_name")
              }
            />
            {Object.keys(filteredPropsSlicers).length > 0 &&
              filteredPropsSlicers.location_name.map((op: any, opIdx: any) =>
                op === "All" ? (
                  <li key={`location_name-${opIdx}`}>
                    <a className="dropdown-item">
                      <div className="form-check text-dark">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`location_name-${opIdx}`}
                          onChange={slicerCheckboxChangeHandler}
                          value={op}
                          checked={
                            tempSelectedSlicers.location_name.findIndex(
                              (value: string) => value === "All"
                            ) !== -1
                          }
                        />
                        <label
                          htmlFor={`location_name-${opIdx}`}
                          className="form-check-label"
                        >
                          {op}
                        </label>
                      </div>
                    </a>
                  </li>
                ) : (
                  <li key={`location_name-${opIdx}`}>
                    <a href="#!" className="dropdown-item">
                      <div className="form-check text-dark">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`location_name-${opIdx}`}
                          onChange={slicerCheckboxChangeHandler}
                          value={op}
                          checked={
                            tempSelectedSlicers.location_name.findIndex(
                              (value: string) => value === op
                            ) !== -1
                          }
                        />
                        <label
                          htmlFor={`location_name-${opIdx}`}
                          className="form-check-label"
                        >
                          {op}
                        </label>
                      </div>
                    </a>
                  </li>
                )
              )}
          </ul>
        </div>

        <div className="d-inline me-1">
          <button type="button" className="btn btn-sm text-white" style={{ backgroundColor: "#094780" }} onClick={setSelectedFilters}>
            <div className="d-flex justify-content-center align-items-center">
              <IoChevronForwardCircleOutline size={20} /> &nbsp;Apply filters
            </div>
          </button>
        </div>

        <div className="d-inline">
          <button type="button" className="btn btn-sm btn-warning" onClick={resetSelectedFilters}>
            <div className="d-flex justify-content-center align-items-center">
              <BiReset size={20} /> &nbsp;Reset filters
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default PlanningParameterSlicers;
