import { createSlice, Reducer, Slice } from "@reduxjs/toolkit";

import {
  PART_MASTER_TAB_ID,
  SITE_MASTER_TAB_ID,
  SOURCE_MASTER_TAB_ID,
  SUPPLIER_MASTER_TAB_ID,
} from "../../shared/constants";
import MasterTablesTabSlice from "../../models/Redux/MasterTablesTabSlice.model";

const initialState: MasterTablesTabSlice = {
  activeSubTabId: SOURCE_MASTER_TAB_ID,
  tabApiDataFetched: {
    [SOURCE_MASTER_TAB_ID]: false,
    [PART_MASTER_TAB_ID]: false,
    [SITE_MASTER_TAB_ID]: false,
    [SUPPLIER_MASTER_TAB_ID]: false,
  },
  isPartMasterUpdated: false,
  isSiteMasterUpdated: false,
  isSourceMasterUpdated: false,
  isSupplierMasterUpdated: false,
  isMasterTablesEdited: {
    partMaster: false,
    siteMaster: false,
    sourceMaster: false,
    supplierMaster: false,
  },
};

const masterTablesTabSlice: Slice = createSlice({
  name: "masterTables",
  initialState,
  reducers: {
    setIsPartMasterUpdated(state, action) {
      state.isPartMasterUpdated = action.payload;
    },
    setIsSiteMasterUpdated(state, action) {
      state.isSiteMasterUpdated = action.payload;
    },
    setIsSourceMasterUpdated(state, action) {
      state.isSourceMasterUpdated = action.payload;
    },

    setIsSupplierMasterUpdated(state, action) {
      state.isSupplierMasterUpdated = action.payload;
    },

    setActiveSubTabId: (state, action: { payload: string }) => {
      state.activeSubTabId = action.payload;
    },
    setTabApiDataFetched: (
      state,
      action: { payload: { [key: string]: false } }
    ) => {
      state.tabApiDataFetched = {
        ...state.tabApiDataFetched,
        ...action.payload,
      };
    },
    resetTabApiDataFetched: (state, action) => {
      state.tabApiDataFetched = {
        [SOURCE_MASTER_TAB_ID]: false,
        [PART_MASTER_TAB_ID]: false,
        [SITE_MASTER_TAB_ID]: false,
        [SUPPLIER_MASTER_TAB_ID]: false,
      };
    },

    setIsMasterTablesEdited(state, action) {
      let { key, value } = action.payload;
      if (state.isMasterTablesEdited.hasOwnProperty(key)) {
        state.isMasterTablesEdited[key] = value;
      }
    },
  },
});

export const masterTablesTabActions = masterTablesTabSlice.actions;

export default masterTablesTabSlice.reducer as Reducer<MasterTablesTabSlice>;
