import { AgGridReact } from "ag-grid-react";
import { AxiosError, AxiosResponse } from "axios";
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { IoSendOutline } from "react-icons/io5";
import { LuRefreshCcw } from "react-icons/lu";
import { SiConvertio } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MdOutlineFileDownload } from "react-icons/md";

import useInput from "../../../hooks/useInput";
import useFetch from "../../../hooks/useFetchMSAL";
import { ReplenishmentPlanTabFetchAPI } from "../../../models/APIResponses.model";
import CalculatedSupplyReceipt from "../../../models/ReplenishmentPlan/CalculatedSupplyReceipt.model";
import { AG_GRID_DEFAULT_COL_DEF, AG_GRID_MODULES, AG_GRID_OPTIONS, CALCULATED_SUPPLY_RECEIPTS_TAB_ID, PLANNED_SUPPLY_RECEIPTS_TAB_ID, TOAST_CONTAINER_ID } from "../../../shared/constants";
import { agGridNumberFormatter, getToastOptions } from "../../../shared/functions";
import { RootState } from "../../../store";
import { replenishmentTabActions } from "../../../store/slices/replenishment-tab-slice";
import "../../../styles.css";
import Modal from "../../UI/Modal/Modal";
import "./CalculatedSupplyReceiptsGrid.css";

const CalculatedSupplyReceiptsGrid = (props: {
    gridHeight: string;
}) => {
    const [{data ,loading, error}, fetchData] = useFetch([]);
    // States
    const [gridRowData, setGridRowData] = useState<CalculatedSupplyReceipt[]>([]);
    const [receiptIdToConvert, setReceiptIdToConvert] = useState<string>("");
    const gridRef = useRef<AgGridReact>(null);

    // Refs
    const modalCloseBtnRef = useRef<HTMLButtonElement>(null);

    // Convert Order Model form inputs
    const {
        value: enteredPoNumber,
        valueIsValid: enteredPoNumberIsValid,
        valueHasError: enteredPoNumberHasError,
        valueChangeHandler: poNumberChangeHandler,
        valueBlurHandler: poNumberBlurHandler,
        resetEnteredValue: resetPoNumber
    } = useInput((value: string) => ((/^[\w\d-]{1,255}$/g).test(value)));

    const {
        value: enteredFirmLineId,
        valueIsValid: firmLineIdIsValid,
        valueHasError: firmLineIdHasError,
        valueChangeHandler: firmLineIdChangeHandler,
        valueBlurHandler: firmLineIdBlurHandler,
        resetEnteredValue: resetFirmLineId
    } = useInput((value: string) => ((/^[\d]+$/g).test(value)));

    // Variables
    const dispatchFn = useDispatch();
    const tabApiDataFetched: boolean = useSelector((state: RootState) => state.replenishmentTab.tabApiDataFetched)[CALCULATED_SUPPLY_RECEIPTS_TAB_ID];
    const activeSubTabId: string = useSelector((state: RootState) => state.replenishmentTab.activeSubTabId);
    const selectedSlicers = useSelector((state: RootState) => state.replenishmentTab.selectedSlicers);
    const isSiteMasterUpdated = useSelector((state: RootState)=>state.masterTables.isSiteMasterUpdated);
    const isPartMasterUpdated = useSelector((state: RootState)=>state.masterTables.isPartMasterUpdated);
    const modalFormIsValid: boolean = enteredPoNumberIsValid && firmLineIdIsValid;
    const convertOrderModalId: string = "convertToFirmOrderModal";
    const gridColDef: any[] = [
        {
            field: "site",
            tooltipField: "site",
            headerTooltip: "Site Name",
            cellDataType: "text",
            headerName: "Site Name",
        },
        {
            field: "sku",
            tooltipField: "sku",
            headerTooltip: "SKU Code",
            cellDataType: "text",
            headerName: "SKU Code",
        },
        {
            field: "supply_id",
            tooltipField: "supply_id",
            headerTooltip: "Supply ID",
            cellDataType: "text",
            headerName: "Supply ID",
        },
        {
            field: "projected_release_date",
            tooltipField: "projected_release_date",
            headerTooltip: "Proj. Release Date",
            cellDataType: "text",
            headerName: "Proj. Release Date",
        },
        {
            field: "expected_receipt_date",
            tooltipField: "expected_receipt_date",
            headerTooltip: "Expected Receipt Date",
            cellDataType: "text",
            headerName: "Expected Receipt Date",
        },
        {
            field: "quantity",
            headerTooltip: "Qty",
            cellDataType: "number",
            cellClass: "ag-right-aligned-cell",
            headerClass: "ag-left-aligned-cell",
            headerName: "Qty",
            tooltipValueGetter: (params: any) => agGridNumberFormatter(params.data.quantity, 2),
            valueFormatter: (params: any) => agGridNumberFormatter(params.data.quantity, 2)
        },
        {
            field: "status",
            tooltipField: "status",
            headerTooltip: "Status",
            cellDataType: "text",
            headerName: "Status",
        },
        {
            headerName: "Actions",
            width: 120,
            cellRenderer: (params: any) => {
                return (
                    <button className="btn btn-sm btn-outline-clear" onClick={(e) => convertOrderModalHandler(params.data.supply_id)} title="Convert to firm order" data-bs-toggle="modal" data-bs-target={`#${convertOrderModalId}`}>
                        <span className="fs-6" style={{ color: "#094780" }}>
                            <SiConvertio />
                        </span> Convert
                    </button>
                );
            }
        }
    ];

    const fetchCalculatedSupplyReceipts = useCallback(async () => {
        const fetchingDataToastId = toast.loading('Fetching calculated supply receipts data...', {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions('loading')
        });

        try {
            const fetchAPIResponse: AxiosResponse<ReplenishmentPlanTabFetchAPI<CalculatedSupplyReceipt[]>> =
            await fetchData(`/calculated-supply-receipts`, {
                method:'GET',
                params: {
                    site_code: selectedSlicers.site_code,
                    part_code: selectedSlicers.part_code,
                    number_of_weeks: selectedSlicers.number_of_weeks
                }
            });

            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });
            
            if (fetchAPIResponse.data.data) {
                setGridRowData(fetchAPIResponse.data.data);
                dispatchFn(replenishmentTabActions.setSlicers(fetchAPIResponse.data.slicers));
                dispatchFn(replenishmentTabActions.setTabApiDataFetched({
                    [CALCULATED_SUPPLY_RECEIPTS_TAB_ID]: true
                }));

            } else {
                toast.error('Error in fetching calculated supply receipts data', {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions('error')
                });
            }

        } catch(error: Error | AxiosError | any) {
            console.error(`Request Error: ${error.name}`);
            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });
            toast.error((
                error?.response?.data?.error || 
                'Error in fetching data'
            ), {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions('error')
            });
        }
    }, [dispatchFn, fetchData, selectedSlicers]);

    const convertOrderModalHandler = (calculatedSupplyId: any) => {
        setReceiptIdToConvert(calculatedSupplyId);
    };

    const onConvertOrderModalClose = (event: any) => {
        setReceiptIdToConvert("");
    };

    const resetConvertModalFormHandler = () => {
        resetPoNumber();
        resetFirmLineId();
    };

    const modalFormSubmissionHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        if (receiptIdToConvert.length === 0) {
            toast.error('Kindly select a supply receipt to convert to firm order.', {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions('error')
            });
            return;
        }

        const convertingOrderToastId = toast.loading('Converting to firm order...', {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions('loading')
        });

        try {
            const newFirmOrder: any = {
                calculated_line_id: receiptIdToConvert,
                po_number: enteredPoNumber,
                firm_line_id: parseInt(enteredFirmLineId)
            };

            const apiResponse: AxiosResponse<{ Message?: string; Error?: string }> =
            await fetchData(`/convert-to-firm-order`, {
                method: "POST",
                data: newFirmOrder
            });

            toast.dismiss({
                id: convertingOrderToastId,
                containerId: TOAST_CONTAINER_ID
            });
            
            if (apiResponse.data.Message) {
                setReceiptIdToConvert("");
                resetConvertModalFormHandler();

                modalCloseBtnRef.current?.click();
                
                toast.success(apiResponse.data.Message, {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions("success")
                });

                fetchCalculatedSupplyReceipts();
                dispatchFn(replenishmentTabActions.setRefreshSummaryGrid(true));
                dispatchFn(replenishmentTabActions.setTabApiDataFetched({
                    [PLANNED_SUPPLY_RECEIPTS_TAB_ID]: false
                }));

            } else {
                toast.error('Error in converting to firm order.', {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions('error')
                });
            }

        } catch(error: Error | AxiosError | any) {
            console.error(`Request Error: ${error.name}`);
            toast.dismiss({
                id: convertingOrderToastId,
                containerId: TOAST_CONTAINER_ID
            });
            toast.error((
                error?.response?.data?.error || 
                'Error in converting to firm order.'
            ), {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions('error')
            });
        }
    };

    // Initial run
    useEffect(() => {
        if (
            !tabApiDataFetched && 
            activeSubTabId === CALCULATED_SUPPLY_RECEIPTS_TAB_ID
        ) {
            fetchCalculatedSupplyReceipts();
        }

    }, [fetchCalculatedSupplyReceipts, fetchData, tabApiDataFetched, activeSubTabId, selectedSlicers, isPartMasterUpdated, isSiteMasterUpdated]);

    const modalForm: ReactElement = (
        <form onSubmit={modalFormSubmissionHandler}>
            <div className="row">
                <div className="col col-6 mb-2">
                    <label htmlFor="po-number" className="form-label">PO Number</label>
                    <input 
                        type="text" 
                        name="po-number" 
                        id="po-number" 
                        className="form-control"
                        value={enteredPoNumber}
                        onChange={poNumberChangeHandler}
                        onBlur={poNumberBlurHandler}
                    />
                    {enteredPoNumberHasError && (
                        <p className="text-danger">Enter a valid PO Number.</p>
                    )}
                </div>

                <div className="col col-6 mb-2">
                    <label htmlFor="firm-line-id" className="form-label">Line ID</label>
                    <input 
                        type="text" 
                        name="firm-line-id" 
                        id="firm-line-id" 
                        className="form-control"
                        value={enteredFirmLineId}
                        onChange={firmLineIdChangeHandler}
                        onBlur={firmLineIdBlurHandler}
                    />
                    {firmLineIdHasError && (
                        <p className="text-danger">Enter a valid numeric Line ID.</p>
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col col-6 mb-2 d-grid">
                    <button disabled={!modalFormIsValid} type="submit" className="btn text-white" style={{ backgroundColor: "#094780" }}>
                        <span className="fs-5 text-white">
                            <IoSendOutline />
                        </span> Submit
                    </button>
                </div>

                <div className="col col-6 mb-2 d-grid">
                    <button disabled={!enteredPoNumber && !enteredFirmLineId} type="button" className="btn btn-warning" onClick={resetConvertModalFormHandler}>
                        <span className="fs-5">
                            <LuRefreshCcw />
                        </span> Reset
                    </button>
                </div>
            </div>
        </form>
    );

    const downloadReports = useCallback(() => {
        const gridApi = gridRef.current?.api;
        if (!gridApi) return;

        const originalColumnDefs = gridApi.getColumnDefs();
    
        // Update column definitions to hide 'Actions' column
        const updatedColumnDefs = originalColumnDefs?.map(colDef => {
        if (colDef.headerName === 'Actions') {
            return { ...colDef, hide: true };
        }
        return colDef;
        });

        // Update grid options to apply changes
        gridApi.updateGridOptions({
        columnDefs: updatedColumnDefs,
        });
    
        // Retrieve all rows
        const allRows:any = [];
        gridApi.forEachNode(node => allRows.push(node.data));
    
        // Filter rows where the status column value is 'Calculated'
        const filteredRows = allRows.filter((row:any) => row.status === 'Calculated');
    
        // Temporarily set the grid data to filteredRows for export
        gridApi.updateGridOptions({
            rowData: filteredRows,
          });
    
        // Export data as Excel
        gridApi.exportDataAsExcel({
          sheetName: 'Filtered Data',
        });
    
        // Restore original data after export
        gridApi.updateGridOptions({
            rowData: gridRowData,
          });
        
      }, [gridRowData]);
    
    return (
        <>  
            <div className="d-flex justify-content-end">
                <button 
                    title="Download Report"
                    className="btn btn-sm btn-custom-primary" 
                    disabled={!gridRowData.length} 
                    onClick={downloadReports}
                >
                    <MdOutlineFileDownload /> &nbsp;Download Report
                </button>
            </div>

            <div className="ag-theme-balham mt-2" style={{ height: props.gridHeight, maxHeight: props.gridHeight, width: '100%', overflowX: 'auto', overflowY: 'auto' }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={gridRowData}
                    columnDefs={gridColDef}
                    gridOptions={{
                        rowHeight: 50,
                        ...(AG_GRID_OPTIONS as any)
                    }}
                    defaultColDef={{
                        width: 100,
                        ...(AG_GRID_DEFAULT_COL_DEF as any)
                    }}
                    modules={AG_GRID_MODULES}
                />
            </div>

            {
                createPortal(
                    <Modal 
                        id={convertOrderModalId}
                        ref={modalCloseBtnRef}
                        title="Convert to Firm Order"
                        onModalClose={onConvertOrderModalClose}
                    >
                        {modalForm}
                    </Modal>,
                    document.body
                )
            }
        </>
    );
};

export default CalculatedSupplyReceiptsGrid;
