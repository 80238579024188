import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { LuPackageSearch, LuWarehouse } from "react-icons/lu";
import { BiReset } from "react-icons/bi";
import { ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";

import "../../../styles.css";
import "./ReplenishmentPlanSlicers.css";
import Dropdown from "../../UI/Dropdown/Dropdown";
import { ReplPlanSelectedSlicerModel, ReplPlanSlicerDropdownModel } from "../../../models/ReplenishmentPlan/ReplPlanSlicer.model";
import { RootState } from "../../../store";
import { replenishmentTabActions } from "../../../store/slices/replenishment-tab-slice";
import { getDefaultReplPlanSlicersInStorage } from "../../../shared/functions";

const ReplenishmentPlanTabSlicers = () => {
    const dispatchFn = useDispatch();
    const slicers: ReplPlanSlicerDropdownModel = useSelector((state: RootState) => state.replenishmentTab.slicers);
    const tempSelectedSlicers: ReplPlanSelectedSlicerModel = useSelector((state: RootState) => state.replenishmentTab.tempSelectedSlicers);

    const numOfWeeksChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        let numberOfWeeks: number = parseInt(event.target.value);
        numberOfWeeks = (numberOfWeeks >= 0) ? numberOfWeeks : 0;
        
        dispatchFn(replenishmentTabActions.setTempSlicers({
            ...tempSelectedSlicers,
            number_of_weeks: numberOfWeeks
        }));
    }

    const applySelectedSlicers = (event: any) => {
        dispatchFn(replenishmentTabActions.setSelectedSlicers(null));
        dispatchFn(replenishmentTabActions.resetTabApiDataFetched(null));
    };

    const resetSelectedSlicers = (event: any) => {
        const defaultReplPlanSlicers: ReplPlanSelectedSlicerModel = getDefaultReplPlanSlicersInStorage();
        
        if (
            tempSelectedSlicers.site_code !== defaultReplPlanSlicers.site_code ||
            tempSelectedSlicers.part_code !== defaultReplPlanSlicers.part_code ||
            tempSelectedSlicers.number_of_weeks !== defaultReplPlanSlicers.number_of_weeks
        ) {
            dispatchFn(replenishmentTabActions.resetSelectedSlicers(null));
            dispatchFn(replenishmentTabActions.resetTabApiDataFetched(null));
        }
    };

    const dropdownItemChangeHandler = (slicer_name: string, dropdownItemValue: any) => {
        dispatchFn(replenishmentTabActions.setTempSlicers({
            ...tempSelectedSlicers,
            [slicer_name]: dropdownItemValue
        }));

        if (slicer_name === "site_code") {
            dispatchFn(replenishmentTabActions.setPartSlicer(dropdownItemValue));
        }
    };

    return (
        <>
            <Dropdown
                id="site-name-dropdown"
                icon={<LuWarehouse size={20} />}
                label="Site Name"
                defaultValue={tempSelectedSlicers.site_code}
                items={slicers.site_codes}
                onItemChanged={(value: any) => dropdownItemChangeHandler("site_code", value)}
            />
            
            <Dropdown
                id="part-code-dropdown"
                icon={<LuPackageSearch size={20} />}
                label="Part Code"
                defaultValue={tempSelectedSlicers.part_code}
                items={slicers.part_codes}
                onItemChanged={(value: any) => dropdownItemChangeHandler("part_code", value)}
            />

            <input 
                className="form-control form-control-sm border-1 border-dark rounded-2 segoe-font"
                type="number" 
                min={0}
                max={slicers.number_of_weeks}
                step={1}
                onChange={numOfWeeksChangeHandler}
                value={tempSelectedSlicers.number_of_weeks}
                style={{
                    display: "inline",
                    width: "100px", 
                    paddingBottom: "8px", 
                    marginRight:"5px", 
                    textAlign: "center"
                }}
                placeholder="Weeks" 
            />

            <div className="d-inline me-1">
                <button type="button" className="btn btn-sm text-white" style={{ backgroundColor: "#094780" }} onClick={applySelectedSlicers}>
                    <div className="d-flex justify-content-center align-items-center">
                        <IoChevronForwardCircleOutline size={20} /> &nbsp;Apply filters
                    </div>
                </button>
            </div>

            <div className="d-inline">
                <button type="button" className="btn btn-sm btn-warning" onClick={resetSelectedSlicers}>
                    <div className="d-flex justify-content-center align-items-center">
                        <BiReset size={20} /> &nbsp;Reset filters
                    </div>
                </button>
            </div>
        </>
    );
};

export default ReplenishmentPlanTabSlicers;
