import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        navigate("/");
    }, [navigate])

    return (
        <>
            <p>Page Not Found</p>
        </>
    );
}
export default PageNotFound