import { ReactElement, useCallback, useEffect, useState } from "react";

import './Dropdown.css';
import DropdownItem from "./DropdownItem/DropdownItem";

interface DropdownItemModel {
  name: string;
  value: any;
}

const Dropdown = (props: {
  id: string;
  icon: ReactElement;
  label: string;
  defaultValue: any;
  items: DropdownItemModel[];
  onItemChanged: Function;
}) => {
  // States
  const [dropdownElementsList, setDropdownElementsList] = useState<
    ReactElement[]
  >([]);
  const [dropdownFilterText, setDropdownFilterText] = useState<string>("");
  const [selectedDropdownLabel, setSelectedDropdownLabel] = useState<any>();

  // Variables
  const { onItemChanged } = { ...props };

  const dropdownValueSelectedHandler = useCallback(
    (dropdownLabel: string, dropdownValue: any) => {
      setSelectedDropdownLabel(dropdownLabel);
      onItemChanged(dropdownValue);
    },
    [onItemChanged]
  );

  const getDropdownItemElements = useCallback(
    (items: DropdownItemModel[]): ReactElement[] => {
      const dropdownItemsList: ReactElement[] = items.map((item, idx) => (
        <DropdownItem
          key={`${item.name}-${idx}`}
          id={`${item.name}-${idx}`}
          name={`${props.id}-item`}
          label={item.name}
          value={item.value}
          defaultValue={props.defaultValue}
          onValueSelected={dropdownValueSelectedHandler}
        />
      ));

      return dropdownItemsList;
    },
    [props.id, props.defaultValue, dropdownValueSelectedHandler]
  );

  const dropdownFilterTextChangeHandler = useCallback(
    (filterText: string) => {
      const filteredDropdownItemsList: DropdownItemModel[] = props.items.filter(
        (item) =>
          item.name.toLowerCase().includes(filterText.trim().toLowerCase())
      );
      const dropdownElementsList = getDropdownItemElements(
        filteredDropdownItemsList
      );
      setDropdownElementsList(dropdownElementsList);
    },
    [getDropdownItemElements, props.items]
  );

  // Getting initial dropdown item JSX elements
  useEffect(() => {
    const allDropdownItems: ReactElement[] = getDropdownItemElements(
      props.items
    );
    setDropdownElementsList(allDropdownItems);

    const defaultValueIdx: number = props.items.findIndex(
      (item) => item.value === props.defaultValue
    );
    setSelectedDropdownLabel(props.items[defaultValueIdx]?.name);
  }, [getDropdownItemElements, props.items, props.defaultValue]);

  // Filter dropdown items after 500 ms of entering filter text
  useEffect(() => {
    const textFilterTimeoutId = setTimeout(() => {
      dropdownFilterTextChangeHandler(dropdownFilterText);
    }, 500);

    return () => clearTimeout(textFilterTimeoutId);
  }, [dropdownFilterText, dropdownFilterTextChangeHandler]);

  return (
    <div className="dropdown d-inline me-1">
      <button
        type="button"
        className="btn btn-sm btn-outline-dark dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span>
          {props.icon && props.icon} &nbsp;{props.label}
        </span>
        {selectedDropdownLabel && `: ${selectedDropdownLabel}`}
      </button>

      <ul className="dropdown-menu scrollable-dropdown">
        <li>
          <input
            type="text"
            id={`${props.id}-text-filter`}
            className="form-control form-control-sm ms-2 mb-2"
            style={{ width: "90%" }}
            placeholder={"\u{1F50E} Search..."}
            onChange={(e) => setDropdownFilterText(e.target.value)}
          />
        </li>

        {dropdownElementsList}
      </ul>
    </div>
  );
};

export default Dropdown;
