import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AxiosError, AxiosResponse } from "axios";
import { AgGridReact } from "ag-grid-react";
import { useSelector, useDispatch } from "react-redux";

import "../../../styles.css";
import "./DemandPeggingGrid.css";
import { agGridNumberFormatter, getToastOptions } from "../../../shared/functions";
import { ReplenishmentPlanTabFetchAPI } from "../../../models/APIResponses.model";
import DemandPegging from "../../../models/ReplenishmentPlan/DemandPegging.model";
import { AG_GRID_DEFAULT_COL_DEF, AG_GRID_MODULES, AG_GRID_OPTIONS, DEMAND_PEGGING_TAB_ID, TOAST_CONTAINER_ID } from "../../../shared/constants";
import { replenishmentTabActions } from "../../../store/slices/replenishment-tab-slice";
import { RootState } from "../../../store";
import useFetch from "../../../hooks/useFetchMSAL";

const DemandPeggingGrid = (props: {
    gridHeight: string;
}) => {
    const [{data ,loading, error}, fetchData] = useFetch([]);
    // States
    const [gridRowData, setGridRowData] = useState<DemandPegging[]>([]);
    
    // Variables
    const tabApiDataFetched: boolean = useSelector((state: RootState) => state.replenishmentTab.tabApiDataFetched)[DEMAND_PEGGING_TAB_ID];
    const activeSubTabId: string = useSelector((state: RootState) => state.replenishmentTab.activeSubTabId);
    const selectedSlicers = useSelector((state: RootState) => state.replenishmentTab.selectedSlicers);
    const dispatchFn = useDispatch();
    const gridColDef: any[] = [
        {
            field: "site",
            tooltipField: "site",
            headerTooltip: "Site Name",
            cellDataType: "text",
            headerName: "Site Name",
            valueGetter: (params: any) => {
                return (params.data.demand_rank === 1) ? params.data.site : null;
            },
            cellClassRules: {
                "top-border": (params: any) => (params.data.demand_rank === 1)
            }
        },
        {
            field: "sku",
            tooltipField: "sku",
            headerTooltip: "SKU Code",
            cellDataType: "text",
            headerName: "SKU Code",
            valueGetter: (params: any) => {
                return (params.data.demand_rank === 1) ? params.data.sku : null;
            },
            cellClassRules: {
                "top-border": (params: any) => (params.data.demand_rank === 1)
            }
        },
        {
            field: "week_ending",
            tooltipField: "week_ending",
            headerTooltip: "Week Ending",
            cellDataType: "text",
            headerName: "Week Ending",
            valueGetter: (params: any) => {
                return (params.data.demand_rank === 1) ? params.data.week_ending : null;
            },
            cellClassRules: {
                "top-border": (params: any) => (params.data.demand_rank === 1)
            }
        },
        {
            field: "total_demand_qty",
            headerTooltip: "Total Demand Qty",
            cellDataType: "number",
            cellClass: "ag-right-aligned-cell",
            headerClass: "ag-left-aligned-cell",
            headerName: "Total Demand Qty",
            tooltipValueGetter: (params: any) => agGridNumberFormatter(params.data.total_demand_qty, 2),
            valueFormatter: (params: any) => {
                return (params.data.demand_rank === 1) ? agGridNumberFormatter(params.data.total_demand_qty, 2) : "";
            },
            cellClassRules: {
                "top-border": (params: any) => (params.data.demand_rank === 1)
            }
        },
        {
            field: "demand",
            headerTooltip: "Fulfilled Demand Qty",
            cellDataType: "number",
            cellClass: "ag-right-aligned-cell",
            headerClass: "ag-left-aligned-cell",
            headerName: "Fulfilled Demand Qty",
            tooltipValueGetter: (params: any) => agGridNumberFormatter(params.data.demand, 2),
            valueFormatter: (params: any) => agGridNumberFormatter(params.data.demand, 2),
            valueGetter: (params: any) => {
                return (params.data.demand_rank === 1) ? params.data.demand : null;
            },
            cellClassRules: {
                "top-border": (params: any) => true
            }
        },
        {
            field: "on_hand_inventory",
            headerTooltip: "Inventory Qty",
            cellDataType: "number",
            cellClass: "ag-right-aligned-cell",
            headerClass: "ag-left-aligned-cell",
            headerName: "Inventory Qty",
            tooltipValueGetter: (params: any) => agGridNumberFormatter(params.data.on_hand_inventory, 2),
            valueFormatter: (params: any) => agGridNumberFormatter(params.data.on_hand_inventory, 2),
            cellClassRules: {
                "top-border": (params: any) => true
            }
        },
        {
            field: "planned_supply",
            headerTooltip: "Planned Supply Qty",
            cellDataType: "number",
            cellClass: "ag-right-aligned-cell",
            headerClass: "ag-left-aligned-cell",
            headerName: "Planned Supply Qty",
            tooltipValueGetter: (params: any) => agGridNumberFormatter(params.data.planned_supply, 2),
            valueFormatter: (params: any) => agGridNumberFormatter(params.data.planned_supply, 2),
            cellClassRules: {
                "top-border": (params: any) => true
            }
        },
        {
            field: "supply_id",
            tooltipField: "supply_id",
            headerTooltip: "Planned Supply ID",
            cellDataType: "number",
            cellClass: "ag-right-aligned-cell",
            headerClass: "ag-left-aligned-cell",
            headerName: "Planned Supply ID",
            valueFormatter: (params: any) => (params.data.supply_id) ? params.data.supply_id : null,
            cellClassRules: {
                "top-border": (params: any) => true
            }
        },
        {
            field: "po_number",
            tooltipField: "po_number",
            headerTooltip: "PO Number",
            cellDataType: "text",
            headerName: "PO Number",
            cellClassRules: {
                "top-border": (params: any) => true
            }
        },
        {
            field: "calculated_supply",
            headerTooltip: "Calculated Supply Qty",
            cellDataType: "number",
            cellClass: "ag-right-aligned-cell",
            headerClass: "ag-left-aligned-cell",
            headerName: "Calculated Supply Qty",
            tooltipValueGetter: (params: any) => agGridNumberFormatter(params.data.calculated_supply, 2),
            valueFormatter: (params: any) => agGridNumberFormatter(params.data.calculated_supply, 2),
            cellClassRules: {
                "top-border": (params: any) => true
            }
        }
    ];

    const isSiteMasterUpdated = useSelector((state: RootState)=>state.masterTables.isSiteMasterUpdated);
    const isPartMasterUpdated = useSelector((state: RootState)=>state.masterTables.isPartMasterUpdated);

    const fetchDemandPeggingData = useCallback(async () => {
        const fetchingDataToastId = toast.loading('Fetching demand pegging data...', {
            containerId: TOAST_CONTAINER_ID,
            ...getToastOptions('loading')
        });
        
        try {
            const fetchAPIResponse: AxiosResponse<ReplenishmentPlanTabFetchAPI<DemandPegging[]>> = 
            await fetchData(`/demand-pegging`, {
                method: 'GET',
                params: {
                    site_code: selectedSlicers.site_code,
                    part_code: selectedSlicers.part_code,
                    number_of_weeks: selectedSlicers.number_of_weeks
                },
                signal: undefined
            });

            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });
            
            if (fetchAPIResponse.data.data) {
                setGridRowData(fetchAPIResponse.data.data);
                dispatchFn(replenishmentTabActions.setSlicers(fetchAPIResponse.data.slicers));
                dispatchFn(replenishmentTabActions.setTabApiDataFetched({
                    [DEMAND_PEGGING_TAB_ID]: true
                }));

            } else {
                toast.error('Error in fetching demand pegging data', {
                    containerId: TOAST_CONTAINER_ID,
                    ...getToastOptions('error')
                });
            }

        } catch(error: Error | AxiosError | any) {
            console.error(`Request Error: ${error.name}`);
            toast.dismiss({
                id: fetchingDataToastId,
                containerId: TOAST_CONTAINER_ID
            });
            toast.error((
                error?.response?.data?.error || 
                'Error in fetching data'
            ), {
                containerId: TOAST_CONTAINER_ID,
                ...getToastOptions('error')
            });
        }
    }, [dispatchFn, fetchData, selectedSlicers]);

    const rowStyleHandler = (params: any): any => {
        return {
            backgroundColor: (params.data.row_index % 2 === 0) ? "#FFFFFF" : "#E6E6E6",
            border: "none"
        };
    };

    useEffect(() => {
        if (
            !tabApiDataFetched && 
            activeSubTabId === DEMAND_PEGGING_TAB_ID
        ) {
            fetchDemandPeggingData();
        }

    }, [fetchDemandPeggingData, fetchData, tabApiDataFetched, activeSubTabId, selectedSlicers, isSiteMasterUpdated, isPartMasterUpdated]);

    
    return (
        <div className="ag-theme-balham mt-2" style={{ height: props.gridHeight, maxHeight: props.gridHeight, width: '100%', overflowX: 'auto', overflowY: 'auto' }}>
            <AgGridReact
                rowData={gridRowData}
                columnDefs={gridColDef}
                gridOptions={{
                    ...(AG_GRID_OPTIONS as any),
                    getRowStyle: rowStyleHandler
                }}
                defaultColDef={{
                    width: 100,
                    ...(AG_GRID_DEFAULT_COL_DEF as any)
                }}
                modules={AG_GRID_MODULES}
            />
        </div>
    );
};

export default DemandPeggingGrid;
