import { useEffect, useState } from "react";
import { LuLineChart, LuPackagePlus, LuPieChart } from "react-icons/lu";
import { TbPackages } from "react-icons/tb";
import { MdAccessTime, MdPolicy } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

import "../../../styles.css";
import "react-toastify/dist/ReactToastify.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.min.css";
import PartSiteSourceTable from "./PartSiteSourceTable";
import SafetyStockPolicyTable from "./SafetyStockPolicyTable";
import TimePhasedSafetyStockTable from "./TimePhasedSafetyStockTable";
import OrderPolicyTable from "./OrderPolicyTable";
import TabDetail from "../../../models/TabDetail.model";
import Tabs from "../../UI/Tabs/Tabs";
import {
  LOT_SIZE_GROUPS_TAB_ID,
  LOT_SIZE_OPTIMIZATION_POLICY_TAB_ID,
  ORDER_POLICY_TAB_ID,
  PART_SITE_SOURCE_TAB_ID,
  SAFETY_STOCK_POLICY_TAB_ID,
  TIME_PHASED_SAFETY_STOCK_TAB_ID,
} from "../../../shared/constants";
import { planningParametersTabActions } from "../../../store/slices/planning-parameters-tab-slice";
import { RootState } from "../../../store";
import LotSizeGroupsGrid from "./LotSizeGroupsGrid";
import LotSizeOptimizationPolicyGrid from "./LotSizeOptimizationPolicyGrid";
import PlanningParameterSlicers from "./PlanningParameterSlicers";
import PartSiteSourceMapping from "../../../models/PlanningParameters/PartSiteSourceMapping.model";
import PartSiteSourceSlicersModel from "../../../models/PlanningParameters/PartSiteSourceSlicers.model";

const PlanningParametersTab = () => {
  // States
  const [partSiteSourceMapping, setPartSiteSourceMapping] = useState<PartSiteSourceMapping[]>([]);
  const [partSiteSourceSlicers, setPartSiteSourceSlicers] = useState<PartSiteSourceSlicersModel>({
    part_name: [],
    source_value: [],
    location_name: [],
  });

  // Redux variables
  const dispatchFn = useDispatch();
  const selectedGridSlicers = useSelector(
    (state: RootState) => state.planningParametersTab.selectedGridSlicers
  );
  const activeSubTabId: { 
    upper: string; 
    lower: string; 
  } = useSelector((state: RootState) => state.planningParametersTab.activeSubTabId);
  const isPlanningParametersTabEdited = useSelector(
    (state: RootState) =>
      state.planningParametersTab.isPlanningParametersTabEdited
  );
  const partSiteSourceTabName = `Part Site Source ${(
    isPlanningParametersTabEdited["partSiteSource"] ? '*' : ''
  )}`;
  const lotSizeGroupsTabName = `Lot Size Groups ${(
    isPlanningParametersTabEdited["lotSizeGroups"] ? '*' : ''
  )}`;
  const safetyStockPolicyTabName = `Safety Stock Policy ${(
    isPlanningParametersTabEdited["safetyStockPolicy"] ? '*' : ''
  )}`;
  const timePhasedSafetyStockTabName = `Time Phased Safety Stock ${(
    isPlanningParametersTabEdited["timePhasedSafetyStock"] ? '*' : ''
  )}`;
  const orderPolicyTabName = `Order Policy ${(
    isPlanningParametersTabEdited["orderPolicy"] ? '*' : ''
  )}`;
  const lotSizeOptimizationTabName = `Lot Size Optimization Policy ${(
    isPlanningParametersTabEdited["lotSizeOptimizationPolicy"] ? '*' : ''
  )}`;

  /**
   * Sets Part Site Source slicers
   * @param {PartSiteSourceSlicersModel} partSiteSourceSlicers Incoming Part Site Source slicers from child component
   */
  const getPartSiteSourceSlicers = (partSiteSourceSlicers: PartSiteSourceSlicersModel): void => {
    setPartSiteSourceSlicers(partSiteSourceSlicers);
  };

  /**
   * Sets Part Site Source mappings
   * @param {PartSiteSourceMapping[]} partSiteSourceMappings Incoming Part Site Source mappings from child component
   */
  const getPartSiteSourceMappings = (partSiteSourceMappings: PartSiteSourceMapping[]): void => {
    setPartSiteSourceMapping(partSiteSourceMappings);
  };

  // Variables
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const upperSectionSubTabs: TabDetail[] = [
    {
      id: PART_SITE_SOURCE_TAB_ID,
      name: partSiteSourceTabName,
      icon: <LuPieChart />,
      content: (
        <PartSiteSourceTable
          gridHeight="30vh"
          getPartSiteSourceSlicers={getPartSiteSourceSlicers}
          getPartSiteSourceMappings={getPartSiteSourceMappings}
        />
      ),
    },
    {
      id: LOT_SIZE_OPTIMIZATION_POLICY_TAB_ID,
      name: lotSizeOptimizationTabName,
      icon: <LuPackagePlus />,
      content: (
        <LotSizeOptimizationPolicyGrid
          gridHeight="30vh"
        />
      ),
    }
  ];
  const lowerSectionSubTabs: TabDetail[] = [
    {
      id: SAFETY_STOCK_POLICY_TAB_ID,
      name: safetyStockPolicyTabName,
      icon: <LuLineChart />,
      content: (
        <SafetyStockPolicyTable
          gridHeight="30vh"
        />
      ),
    },
    {
      id: TIME_PHASED_SAFETY_STOCK_TAB_ID,
      name: timePhasedSafetyStockTabName,
      icon: <MdAccessTime />,
      content: (
        <TimePhasedSafetyStockTable
          gridHeight="30vh"
        />
      ),
    },
    {
      id: ORDER_POLICY_TAB_ID,
      name: orderPolicyTabName,
      icon: <MdPolicy />,
      content: (
        <OrderPolicyTable
          gridHeight="30vh"
        />
      ),
    },
    {
      id: LOT_SIZE_GROUPS_TAB_ID,
      name: lotSizeGroupsTabName,
      icon: <TbPackages />,
      content: (
        <LotSizeGroupsGrid
          gridHeight="30vh"
        />
      ),
    }
  ];

  /**
   * Sets active sub-tab ID
   * @param {"upper" | "lower"} section Section for which active sub-tab ID to update
   * @param {string} activeSubTabId Tab ID to set as active
   */
  const setActiveSubTabId = (section: "upper" | "lower", activeSubTabId: string): void => {
    dispatchFn(planningParametersTabActions.setActiveSubTabId({
      [section]: activeSubTabId
    }));
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [navigate, isAuthenticated]);

  return (
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col col-8 text-start">
          <PlanningParameterSlicers
            slicers={partSiteSourceSlicers}
            selectedSlicers={selectedGridSlicers}
            partSiteSourceMapping={partSiteSourceMapping}
          />
        </div>
      </div>

      <Tabs 
        tabs={upperSectionSubTabs} 
        activeTabIndex={upperSectionSubTabs.findIndex(tab => tab.id === activeSubTabId.upper)}
        getActiveTabId={(tabId: string) => setActiveSubTabId("upper", tabId)} 
      />

      <div className="mt-2">
        <Tabs 
          tabs={lowerSectionSubTabs} 
          activeTabIndex={lowerSectionSubTabs.findIndex(tab => tab.id === activeSubTabId.lower)}
          getActiveTabId={(tabId: string) => setActiveSubTabId("lower", tabId)} 
        />
      </div>
    </div>
  );
};

export default PlanningParametersTab;
