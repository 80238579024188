import {
  ClipboardModule,
  ColDef,
  ExcelExportModule,
  GridOptions,
  MenuModule,
  Module,
  RangeSelectionModule,
} from "ag-grid-enterprise";
import { PublicClientApplication } from "@azure/msal-browser";

// Microsoft Authentication Library (MSAL)
export const REDIRECT_URI = `https://${window.location.host}/login`;

export const MSAL_CONFIG: any = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority:
      "https://login.microsoftonline.com/" +
      process.env.REACT_APP_TENANT_ID +
      "/",
    redirectUri: `${REDIRECT_URI}`,
  },
};

export const MSAL_INSTANCE = new PublicClientApplication(MSAL_CONFIG);

export const PROTECTED_RESOURCES = {
  scopes: ["https://planvida-api-repplan.valuechainplanning.com/api.ReadWrite"],
};

// Miscellaneous constants
export const TOAST_CONTAINER_ID: string = "Replenishment_Planner_Web_App";

// Replenishment tab IDs
export const REPLENISHMENT_PLAN_TAB_ID: string = "replenishmentPlan";
export const S_AND_OP_CHARTS_TAB_ID: string = "sAndOpCharts";
export const DEMAND_PEGGING_TAB_ID: string = "demandPegging";
export const PLANNED_SUPPLY_RECEIPTS_TAB_ID: string = "plannedSupplyReceipts";
export const CALCULATED_SUPPLY_RECEIPTS_TAB_ID: string =
  "calculatedSupplyReceipts";
export const BUY_PLAN_TAB_ID: string = "BuyPlan";

// Planning Parameters tab IDs
export const PART_SITE_SOURCE_TAB_ID: string = "partSiteSource";
export const LOT_SIZE_GROUPS_TAB_ID: string = "lotSizeGroups";
export const PLANNING_PARAMETERS_TAB_ID: string = "planningParameters";
export const SAFETY_STOCK_POLICY_TAB_ID: string = "safetyStockPolicy";
export const TIME_PHASED_SAFETY_STOCK_TAB_ID: string = "timePhasedSafetyStock";
export const ORDER_POLICY_TAB_ID: string = "orderPolicy";
export const LOT_SIZE_OPTIMIZATION_POLICY_TAB_ID: string = "lotSizeOptimizationPolicy";

// Demand Planning tab IDs
export const DEMAND_PLANNING_TAB_ID: string = "demandPlanning";

// Master Table tab IDs
export const MASTER_TABLES_TAB_ID: string = "masterTables";
export const SOURCE_MASTER_TAB_ID: string = "sourceMaster";
export const PART_MASTER_TAB_ID: string = "partMaster";
export const SITE_MASTER_TAB_ID: string = "siteMaster";
export const SUPPLIER_MASTER_TAB_ID: string = "supplierMaster";

// Common AG Grid options
export const AG_GRID_OPTIONS: GridOptions<any> = {
  rowHeight: 22,
  suppressRowHoverHighlight: true,
  enableRangeSelection: true,
  suppressMovableColumns: true,
  suppressRowDrag: true,
  clipboardDelimiter: ",",
  getContextMenuItems: (params: any) => [
    "copy",
    "copyWithHeaders",
    "excelExport",
  ],
};

// Common AG Grid default column definitions
export const AG_GRID_DEFAULT_COL_DEF: ColDef<any> = {
  wrapText: true,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  suppressHeaderMenuButton: true,
  sortable: false,
};

// Common default AG Grid modules
export const AG_GRID_MODULES: Module[] = [
  RangeSelectionModule,
  ClipboardModule,
  MenuModule,
  ExcelExportModule,
];
