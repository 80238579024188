import { useDispatch, useSelector } from "react-redux";
import { IoBarChartOutline, IoCalculatorOutline, IoReceiptOutline } from "react-icons/io5";

import DemandPeggingGrid from "./DemandPeggingGrid";
import TabDetail from "../../../models/TabDetail.model";
import Tabs from "../../UI/Tabs/Tabs";
import PlannedSupplyReceiptsGrid from "./PlannedSupplyReceiptsGrid";
import CalculatedSupplyReceiptsGrid from "./CalculatedSupplyReceiptsGrid";
import ReplenishmentPlanSummaryGrid from "./ReplenishmentPlanSummaryGrid";
import { replenishmentTabActions } from "../../../store/slices/replenishment-tab-slice";
import { BUY_PLAN_TAB_ID, CALCULATED_SUPPLY_RECEIPTS_TAB_ID, DEMAND_PEGGING_TAB_ID, PLANNED_SUPPLY_RECEIPTS_TAB_ID, S_AND_OP_CHARTS_TAB_ID } from "../../../shared/constants";
import { SAndOPCharts } from "./S&OPCharts";
import { MdOutlineSsidChart } from "react-icons/md";
import BuyPlanGrid from "./BuyPlanGrid";
import { GrPlan } from "react-icons/gr";
import { RootState } from "../../../store";

const ReplenishmentPlanTab = () => {    
    // Variables
    const dispatchFn = useDispatch();
    const activeSubTabId: string = useSelector((state: RootState) => state.replenishmentTab.activeSubTabId);
    const subTabs: TabDetail[] = [
        {
            id: S_AND_OP_CHARTS_TAB_ID,
            name: "S&OP Charts",
            icon: <MdOutlineSsidChart />,
            content: (
                <SAndOPCharts />
            )
        },
        {
            id: DEMAND_PEGGING_TAB_ID,
            name: "Demand Pegging",
            icon: <IoBarChartOutline />,
            content: (
                <DemandPeggingGrid 
                    gridHeight="30vh"
                />
            )
        },
        {
            id: PLANNED_SUPPLY_RECEIPTS_TAB_ID,
            name: "Planned Supply Receipts",
            icon: <IoReceiptOutline />,
            content: (
                <PlannedSupplyReceiptsGrid 
                    gridHeight="30vh"
                />
            )
        },
        {
            id: CALCULATED_SUPPLY_RECEIPTS_TAB_ID,
            name: "Calculated Supply Receipts",
            icon: <IoCalculatorOutline />,
            content: (
                <CalculatedSupplyReceiptsGrid 
                    gridHeight="30vh"
                />
            )
        },
        {
            id: BUY_PLAN_TAB_ID,
            name: "Buy Plan",
            icon: <GrPlan />,
            content: (
                <BuyPlanGrid 
                    gridHeight="30vh"
                />
            )
        }
    ];

    const setActiveSubTabId = (activeSubTabId: string): void => {
        dispatchFn(replenishmentTabActions.setActiveSubTabId(activeSubTabId));
    };

    return (
        <div className="container-fluid">
            <ReplenishmentPlanSummaryGrid 
                gridHeight="37vh"
            />

            <div className="mt-2">
                <Tabs
                    tabs={subTabs}
                    activeTabIndex={subTabs.findIndex(tab => tab.id === activeSubTabId)}
                    getActiveTabId={setActiveSubTabId}
                />
            </div>
        </div>
    );
};

export default ReplenishmentPlanTab;
