const DropdownItem = (props: {
    id: string;
    name: string;
    label: string;
    value: any;
    defaultValue: any;
    onValueSelected: Function;
}) => {
    return (
        <li>
            <a className="dropdown-item">
                <div className="form-check text-dark">
                    <input type="radio" className="form-check-input" id={props.id} name={props.name} value={props.value || ""} checked={props.value === props.defaultValue} onChange={(e) => props.onValueSelected(props.label, e.target.value)} />
                    <label htmlFor={props.id} className="form-check-label">
                        {props.label}
                    </label>
                </div>
            </a>
        </li>
    );
};

export default DropdownItem;
